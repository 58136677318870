<template>
    <div class="container-fluid" id="main1">
        <!-- v-if="me && me.id>0"> -->
        <div class="row main-content">
            <div class="col sidebar">
                <div class="text-left " style="margin-top:80px">
                    <div class="title">
                        <p class="mb-1">
                            <i class="fas fa-check text-success mr-1"></i>
                            {{$t('sidebar.uzivatel')}}:
                        </p>
                    </div>

                  <div class="user mx-3">
                    <p class="mb-0">{{$t('sidebar.name')}}: {{me.name ? me.name : me.email}}</p>

                    <p v-if="(me.company ? me.company.name : null) || me.company_name  " class="font-weight-bold mb-1">
                      {{$t('sidebar.company')}}: {{ (me.company ? me.company.name : null) || me.company_name  }}
                    </p>

                    <p class="mb-0">{{$t('sidebar.country')}}: {{me.country}}</p>
                  </div>

                    <div class="mt-3 pt-3 " style="border-bottom: 1px solid green">

                    </div>
                    <div class="mx-3 pt-3">

                        <ul class="pl-1">

                         <!--   <li><router-link to="/admin/options" class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('menu.options')}}</router-link></li>

                            -->
                            <li><router-link to="/admin/companies" class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('menu.companies')}}</router-link></li>

                            <li>
                                <router-link to="/admin/translator" class="btn btn-sm mx-2" v-if="me && me.role>9">{{$t('menu.translator')}}</router-link></li>

                          <!--  <li><router-link to="activity-types" class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('menu.typy-aktivit')}}</router-link></li>-->


                          <!--  <li><router-link to='/admin/motor-notices' class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('admin.motor-notices')}}</router-link></li>-->

                            <li><router-link to='/admin/interval-settings' class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('admin.interval-settings')}}</router-link></li>
                            <li><router-link to='/admin/alarms' class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('admin.alarm-settings')}}</router-link></li>

                          <li><router-link to='/admin/confirmation' class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('b2bein.admin.potvrzovani_prispevku')}}</router-link></li>
                          <li><router-link to='/admin/licences' class="btn btn-sm mx-2" v-if="me && me.role>10">{{$t('menu.licences')}}</router-link></li>


                        </ul>
                    </div>
                </div>
            </div>

            <div class="col content">
                <div class="row h-100 pb-2 mb-2">
                    <div class="col px-3 px-sm-4">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>

        <div class="row bottom-content justify-content-center p-2">
            <div class="mobile-app col col-10 text-center">
                <h4 class="h6 font-weight-bold mb-2">{{$t('mobile-app.stahnete-si')}}</h4>
                <div class="store-icons text-center">
                    <a href="https://play.google.com/store" target="_blank">
                        <img class="google" src="/img/google-play-badge_CS.png" alt />
                    </a>
                    <a href="https://www.apple.com/cz/ios/app-store/" target="_blank">
                        <img class="apple" src="/img/App_Store_Badge_CS.svg" alt />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src

    //const qs = require("qs");
    //const axios = require("axios");
    //axios.defaults.withCredentials = true;

    export default {
        name: "Admins",
        components: {},
        data() {
            return {

            };
        },
        mounted() {
            console.log("mounted main", this.$store.getters.me);
        },
        computed: {
            me() {
                if (this.$store.getters.me)
                    return this.$store.getters.me;
                else
                    return {}
            }
        },
        methods: {

        }
    };
</script>

<style>
    img {
        max-width: 80%;
    }
</style>
